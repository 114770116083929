import React from "react";
import { useState } from "react";
import styles from "../../../pages/styles";
import { starsalone } from "../../../assets/images";

const FAQCard = ({ question, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="p2">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex gap-2 w-full"
      >
        {accordionOpen ? (
          <span
            className={`${styles.paragraph} text-pinkprimary font-extrabold`}
          >
            -
          </span>
        ) : (
          <span
            className={`${styles.paragraph} text-pinkprimary font-extrabold`}
          >
            +
          </span>
        )}
        <span className={`${styles.paragraph} text-left`}>{question}</span>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm 
    ${
      accordionOpen
        ? `grid-rows-[1fr] opacity-100`
        : `grid-rows-[0fr] opacity-0`
    }`}
      >
        <div
          className={`${styles.paragraph} !text-bluefaded text-lg text-left overflow-hidden`}
        >
          {answer}
        </div>
      </div>
    </div>
  );
};

const Accordion = () => {
  return (
    <div className="flex-col justify-center">
      <div className="flex flex-col gap-4 bg-whiteprimary rounded-3xl px-9 py-14 relative z-20">
        <img
          alt="stars background"
          src={starsalone}
          className="absolute right-8 top-5 w-28 -z-10"
        />
        <h1 className={`${styles.headingdark} mb-1`}>
          Frequently Asked Questions
        </h1>
        <FAQCard
          question="What age groups are your courses suitable for?"
          answer="We offer a range of programs catering to all different age groups and skill levels."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="How do you adapt to my child's learning pace?"
          answer="We customize the curriculum to match your child's individual needs and learning pace"
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="What programs do you offer?"
          answer="At the moment, we offer three programs. Private 1:1, Group, and Navigator. The group classes have 4-5 students. Private Classes follow certain course work while Navigator is flexible and personalized to students ability and needs"
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="My student doesn’t learn well in groups. Do you offer private classes?"
          answer="Absolutely! We understand that every student is unique. We offer one-on-one private virtual classes to cater to individual learning styles"
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="Do you offer in-person classes?"
          answer="At the moment, all our classes are conducted online, and we do not offer in-person sessions. Our virtual private classes provide a safe and personalized learning experience for your child"
        />
      </div>
    </div>
  );
};

export default Accordion;
