import React from "react";
import { Helmet } from "react-helmet";
import { ContactUs } from "../../components";
import { textlogo, thumbsupkid } from "../../assets/images";
import styles from "../styles";

const ContactUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Contact Us at Tech Tails. Empower your child's learning journey with
          no sales pressure. Schedule a fun and free session today!
        </title>
        <meta
          name="description"
          content="Join Tech Tails for a complimentary 1-hour trial class. Empower your child's learning journey with no sales pressure. Schedule a fun and free session today!"
        />
        <meta
          name="keywords"
          content="free trial class, children education, Tech Tails, learning, complimentary session, no sales pressure"
        />
        <meta
          property="og:title"
          content="Join Tech Tails for a complimentary 1-hour trial class. Empower your child's learning journey with no sales pressure. Schedule a fun and free session today!"
        />
      </Helmet>
      <div className="w-full">
        <div className="flex sm:flex-row flex-col items-center px-7 gap-8">
          <div className="order-1 flex items-end w-1/3 min-w-60">
            <img
              src={thumbsupkid}
              alt="Thumbs Up Kid"
              className="w-96 h-auto"
            />
          </div>
          <div className="">
            <div className="">
              <img src={textlogo} alt="Tech Tails" className="w-40 mb-4" />
            </div>
            <h2 className={`${styles.biggerheadingdark} text-left`}>
              Don't know where to start? We can help!
            </h2>
            <p className={`${styles.paragraph} text-left`}>
              Contact us today to learn about all the programs we offer and how
              we can help your child succeed in the world of technology!
            </p>
          </div>
        </div>
      </div>
      <ContactUs rounded="true" />
    </div>
  );
};

export default ContactUsPage;
