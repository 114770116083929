import React from "react";
import { FreeTrial } from "../../components/index";
import { ContactUs } from "../../components/index";
import { Helmet } from "react-helmet";
import TrialForm from "./TrialForm";

const FreeTrialPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Join Tech Tails for a complimentary 1-hour trial class. Empower your
          child's learning journey with no sales pressure. Schedule a fun and
          free session today!
        </title>
        <meta
          name="description"
          content="Join Tech Tails for a complimentary 1-hour trial class. Empower your child's learning journey with no sales pressure. Schedule a fun and free session today!"
        />
        <meta
          name="keywords"
          content="free trial class, children education, Tech Tails, learning, complimentary session, no sales pressure"
        />
        <meta
          property="og:title"
          content="Join Tech Tails for a complimentary 1-hour trial class. Empower your child's learning journey with no sales pressure. Schedule a fun and free session today!"
        />
      </Helmet>
      <FreeTrial />
      <TrialForm />
    </div>
  );
};

export default FreeTrialPage;
