import React from "react";
import styles from "../../../pages/styles";

const TextAreaField = ({
  title,
  name,
  value,
  onChange,
  placeholder,
  className,
}) => (
  <div className={`${styles.paragraph} mb-0`}>
    {title}
    <textarea
      cols="10"
      rows="5"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full text-base border-pinkprimary border-2 rounded-2xl px-4 py-2 focus:outline-none focus:border-blueprimary col-span-2"
    ></textarea>
  </div>
);

export default TextAreaField;
