import React from "react";
import styles from "../../pages/styles";

const AboutClasses = ({ title, desc, image }) => {
  return (
    <div className="flex md:flex-row items-center justify-center flex-col bg-blueprimary rounded-t-xl px-3">
      <div className="flex flex-end w-[350px] md:order-first order-last">
        <img src={image} className="" />
      </div>
      <div className="my-auto md:pt-0 pt-12 md:w-1/2 px-4">
        <h1 className={`${styles.biggerheadingdark} text-center mb-5`}>
          {title}
        </h1>
        <p className={`${styles.paragraph} text-center`}>{desc}</p>
      </div>
    </div>
  );
};

export default AboutClasses;
