import React from "react";
import {
  AboutClasses,
  HeroClasses,
  SkillsClasses,
  TrialBar,
  TechRequiredClasses,
  PricingClasses,
} from "../../components";
import { aboutart, coding, darthero } from "../../assets/images";
import { Helmet } from "react-helmet";

const DArtClass = () => {
  return (
    <div className="md:pt-4 pt-0">
      <Helmet>
        <title>3D Digital Art Classes for Kids | TechTails</title>
        <meta
          name="description"
          content="Unlock your child's creative potential with TechTails 3D Digital Art Classes. From imagination to digital creation, make screen time educational and fun."
        />
        <meta
          property="og:title"
          content="3D Digital Art Classes for Kids | TechTails"
        />
        <meta
          property="og:description"
          content="Transform screen time into a creative journey with our 3D Digital Art classes. Dive into 3D modeling and sculpting, and watch your child's imagination come to life."
        />
      </Helmet>
      <HeroClasses
        title="3D Digital Art"
        tagline="Replace hours of gaming with valuable learning"
        desc="🔄 Switch Up Playtime for Learning Adventures: 🎮➡️📚 Trade hours of gaming for invaluable learning experiences, where each session is a step towards mastering new skills 🛠️ and uncovering the exciting world of coding and technology!"
        img={darthero}
      />
      <AboutClasses
        title="About Our 3D Digital Art Class"
        desc="Join us for an adventure in 3D Modeling and Sculpting! 🎨 Your child will learn to shape their imagination into digital art, transforming screen time into a creative and tech-savvy experience. They’ll get hands-on with 3D tools, exploring and creating, as they grow into young digital artists and tech enthusiasts! 🎨🖥️"
        image={aboutart}
      />
      <SkillsClasses
        title="Level Up Your Child's Skills"
        desc="Boost your kid's skills in a fun and cool way with our 3D Digital Art class!"
      />
      <PricingClasses
        title="Private 1:1 Pricing"
        desc="🚀 Our pricing is discounted as we enter the early stages of welcoming new students beyond friends and family."
      />
      <TechRequiredClasses />
    </div>
  );
};

export default DArtClass;
