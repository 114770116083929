import React from "react";
import styles from "../../pages/styles";

const ImageText = ({ title, desc, img, bgcolor }) => {
  return (
    <div
      className={`${bgcolor} flex md:flex-row items-center justify-center flex-col rounded-t-xl px-3`}
    >
      <div className="flex flex-end w-[380px] md:order-first order-last">
        <img src={img} className="" />
      </div>
      <div className="my-auto md:pt-0 pt-14 md:w-1/2 px-2">
        <h1 className={`${styles.biggerheadingdark} text-center mb-5`}>
          <span className="underline decoration-pinkprimary decoration-[6px]">
            {title}
          </span>{" "}
          Program
        </h1>
        <p className={`${styles.paragraph} text-center`}>{desc}</p>
      </div>
    </div>
  );
};

export default ImageText;
