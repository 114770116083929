import React from "react";
import styles from "../../../pages/styles";

const InputField = ({ title, name, value, onChange, placeholder }) => (
  <div className={`${styles.paragraph} mb-2`}>
    {title}
    <input
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required
      className="w-full text-base rounded-xl border-pinkprimary border-2 px-4 py-2 focus:outline-none focus:border-orangeprimary"
    />
  </div>
);

export default InputField;
