import {
  bottomhero,
  textlogo,
  logowithclouds,
  farcloudshero,
  downarrow,
} from "../../../assets/images";
import React from "react";
import styles, { elements } from "../../../pages/styles";
import { Parallax } from "react-scroll-parallax";

const Hero = () => {
  return (
    <section
      id="home"
      className="flex flex-col justify-evenly -mt-4 white-primary"
    >
      <div className="flex flex-col sm:h-[30vh] h-[20vh] sm:min-h-[600px] min-h-[600px] items-center justify-center relative">
        <div className="absolute top-0 -z-20 w-full grainytop"></div>
        <div className="flex flex-col items-center sm:-mt-40 -mt-32">
          <Parallax
            speed={2}
            className="sm:min-w-[300px] min-w-[260px] w-[55%]"
          >
            <img src={logowithclouds} alt="Logo With Clouds" />
          </Parallax>
          <Parallax
            speed={4}
            className="sm:min-w-[240px] w-[160px] mt-[-15px] sm:mb-4 mb-2"
          >
            <img src={textlogo} alt="Tech Tails" />
          </Parallax>
          <img
            src={farcloudshero}
            alt="Tech Tails Clouds"
            className="absolute top-[19%] -z-20 min-w-[200px]"
          />
          <Parallax speed={4} className="text-center">
            <h1 className={`${styles.heading2} sm:px-0 px-6`}>
              We Believe Every Brain Deserves to Find Their Tech Adventure
            </h1>
          </Parallax>
        </div>
        <Parallax
          speed={-4}
          className="min-w-[700px] -z-10 w-[80%] absolute sm:-bottom-2 -bottom-1"
        >
          <div id="about">
            <img src={bottomhero} alt="Tech Tail Animals" />
          </div>
        </Parallax>
        <a
          href="#about"
          className="absolute w-[4%] bottom-16 min-w-[44px] float bg-whiteprimary hover:bg-pinkprimary rounded-full"
        >
          <img src={downarrow} alt="Down Arrow" />
        </a>
      </div>
    </section>
  );
};

export default Hero;
