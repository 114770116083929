import React from "react";
import styles from "../../pages/styles";
import { BrainCircuit, Palette, Route, Users } from "lucide-react";
import { kidpointingdown } from "../../assets/images";

const NavigatorAbout = () => {
  return (
    <div className="bg-pinkprimary flex flex-col items-center justify-center w-full pt-6 md:pt-12 pb-28 rounded-t-3xl relative">
      <div className="text-center max-w-4xl px-2">
        <h2 className={`${styles.headingdark} mb-4`}>
          Making Learning Possible for Every Individual through Our Navigator
          Program
        </h2>
        <p className={`${styles.paragraph} mb-8`}>
          The Navigator Program is shaped to focus on creativity and skill set
          through carefully designed challenges, encouraging growth.
        </p>
      </div>
      <div className="flex flex-wrap justify-evenly w-full md:px-32">
        <BenefitCard
          title="Boost Self Confidence"
          img=<Users className="w-12 h-12" />
        />
        <BenefitCard
          title="Strengthen Problem Solving"
          img=<BrainCircuit className="w-12 h-12" />
        />
        <BenefitCard
          title="Spark Creative Curiousity"
          img=<Palette className="w-12 h-12" />
        />
        <BenefitCard
          title="Promote Career Exploration"
          img=<Route className="w-12 h-12" />
        />
      </div>

      <div className="absolute -bottom-[74px] sm:left-10 -left-8 w-[400px]">
        <img src={kidpointingdown} />
      </div>
    </div>
  );
};

const BenefitCard = ({ title, desc, img }) => {
  return (
    <div className="flex flex-col justify-center text-center max-w-[200px]">
      <div
        className={`${styles.paragraph} flex flex-col gap-2 items-center p-2`}
      >
        {img}
        <p className={`${styles.paragraph} font-semibold text-lg mb-0`}>
          {title}
        </p>
        <p className={`${styles.paragraph} mb-0`}>{desc}</p>
      </div>
    </div>
  );
};

export default NavigatorAbout;
