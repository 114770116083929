import React from "react";
import styles, { elements } from "../../pages/styles";
import { TypeAnimation } from "react-type-animation";
import { teacherzoom, textlogo } from "../../assets/images";

const FreeTrialHero = ({ title, desc, img, bgcolor }) => {
  return (
    <div
      className={`${bgcolor} flex md:flex-row items-center justify-center flex-col rounded-t-xl relative`}
    >
      <div className="flex md:w-11/12 w-full order-first -mt-4 min-w-[600px] relative">
        <img src={img} alt="" className="w-full" />
        <img
          src={teacherzoom}
          alt="Teacher Zoom"
          className={`lg:w-60 md:w-52 w-40 teacherZoomImg`}
        />
      </div>
      <div className="md:my-auto md:w-6/12 px-3 md:text-left text-center my-10">
        <div className="md:justify-start justify-center md:w-32 lg:w-full hidden md:flex w-full md:mt-8">
          <img src={textlogo} className="w-40 mb-4" alt="Tech Tails Logo" />
        </div>
        <h1 className={`${styles.biggerheadingdark} mb-4`}>
          Unlock Your Child's
          <br />
          <span className="underline decoration-pinkprimary decoration-[6px]">
            Potential
            <br />
          </span>
          In{" "}
          <TypeAnimation
            repeat={Infinity}
            speed={5}
            sequence={[
              "Technology",
              2000,
              "Creativity",
              2000,
              "Learning",
              2000,
              "Game Design",
              2000,
              "Coding",
              2000,
            ]}
          />
        </h1>
        <p className={`${styles.paragraph} mb-8 md:hidden lg:block`}>
          We're Tech Tails from Salt Lake City, Utah 🏔️ Our mission? To make
          tech learning fun and personal! Think of us as remote tech guides 🚀
        </p>
        <p className={`${styles.paragraph} mb-8 md:block hidden lg:hidden`}>
          We make technology learning fun and personal! Think of us as remote
          tech guides 🚀
        </p>
        <a className={`${elements.bluebutton} !py-3 `} href="/pages/freetrial">
          Request Free Trial Class
        </a>
      </div>
    </div>
  );
};

export default FreeTrialHero;
