import React from "react";
import styles from "../../../pages/styles";
import {
  farcloudsourapproach,
  ourapproach,
  zoomcall,
} from "../../../assets/images";
import { Parallax } from "react-scroll-parallax";

const OurApproach = () => {
  return (
    <div
      id="ourapproach"
      className="bg-orangeprimary relative -mt-16 -z-10 pt-6 lg:px-32 md:px-16 px-4"
    >
      <div className="w-full relative z-20 h-10 rounded-b-[3rem]"></div>
      <div className="flex flex-col md:flex-row lg:items-end items-center pt-10 md:pb-14 pb-8 px-0 md:gap-10 gap-0 relative">
        <div className="md:flex-1 ml-0 text-center md:text-left">
          <h1 className={`${styles.headingdark} mb-1`}>Our Approach</h1>
          <p className={`${styles.paragraph} mb-2 drop-shadow-md`}>
            Teaching with Technology to Spark the Curiosity to Learn
          </p>
          <ul className={`${styles.paragraph} flex flex-col gap-2`}>
            <li className="md:border-none border-whiteprimary border-4 rounded-xl p-2 bg-white">
              👩‍🏫 Dedicated Expert Instructors
            </li>
            <li className="md:border-none border-whiteprimary border-4 rounded-xl p-2 bg-white">
              🌍 Inclusive Tech Education for Kids of All Abilities
            </li>
            <li className="md:border-none border-whiteprimary border-4 rounded-xl p-2 bg-white">
              🕒 Flexible Scheduling for Busy Families
            </li>
            <li className="md:border-none border-whiteprimary border-4 rounded-xl p-2 bg-white">
              👩‍💻 Interactive, Virtual Classes
            </li>
            <li className="md:border-none border-whiteprimary border-4 rounded-xl p-2 bg-white">
              🧠 STEAM Learning & Social Skills
            </li>
            <li className="md:border-none border-whiteprimary border-4 rounded-xl p-2 bg-white">
              🎉🎮 Fun, Hands-On Learning with Every Session!
            </li>
          </ul>
        </div>

        <div className="min-w-[700px] w-[50%] absolute right-4 -top-4 -z-10">
          <img src={farcloudsourapproach} alt="Far Clouds" />
        </div>
      </div>
    </div>
  );
};

export default OurApproach;
