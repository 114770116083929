import React from "react";
import { useState } from "react";
import { starsalone } from "../../assets/images";
import styles from "../styles";

const FAQCard = ({ question, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="p-1">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex gap-2 w-full"
      >
        {accordionOpen ? (
          <span
            className={`${styles.paragraph} text-darkprimary font-extrabold`}
          >
            -
          </span>
        ) : (
          <span
            className={`${styles.paragraph} text-darkprimary font-extrabold`}
          >
            +
          </span>
        )}
        <span className={`${styles.paragraph} text-left`}>{question}</span>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm 
    ${
      accordionOpen
        ? `grid-rows-[1fr] opacity-100`
        : `grid-rows-[0fr] opacity-0`
    }`}
      >
        <div
          className={`${styles.paragraph} !text-bluefaded text-lg text-left overflow-hidden`}
        >
          {answer}
        </div>
      </div>
    </div>
  );
};

const Accordion = () => {
  return (
    <div className="flex-col justify-center">
      <div className="flex flex-col gap-4 bg-whiteprimary rounded-3xl px-9 relative z-20">
        <img
          alt="stars background"
          src={starsalone}
          className="absolute right-8 top-5 w-28 -z-10"
        />
        <h1 className={`${styles.biggerheadingdark} mb-1`}>
          Frequently Asked Questions
        </h1>
        <h1 className={`${styles.headingdark} mb-1`}>
          Course Content and Structure
        </h1>
        <FAQCard
          question="Are there any prerequisites for enrolling in the game design courses?"
          answer="No prior experience is required for our beginner courses. For advanced courses, we recommend completing our beginner or intermediate levels or having equivalent knowledge."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="How long does each course last, and what is the time commitment per week?"
          answer="Typically our students will do 1-2 years with us, with one or two 60-minute session per week, plus recommended practice time outside of class."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="What programming languages and tools do the students learn?"
          answer="Students start with their level for fundamental concepts and progress to learning, Unity, or Unreal Engine for advanced game design and programming."
        />
        <div className="border border-darkprimary mb-8" />
        <h1 className={`${styles.headingdark} mb-1`}>
          Learning Environment and Support
        </h1>
        <FAQCard
          question="How do you ensure a safe and supportive online learning environment?"
          answer="We use secure platforms for all our classes, monitor all sessions for safety, and our instructors are trained to create a positive, inclusive atmosphere."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="What is the student-to-teacher ratio in your courses?"
          answer="To ensure personalized attention, our group classes maintain a low student-to-teacher ratio of 5:1."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="How do you tailor the learning experience to fit the needs of each student?"
          answer="Our instructors assess each student's skill level and interests at the beginning of the course and adapt the curriculum to meet their individual learning goals."
        />
        <div className="border border-darkprimary mb-8" />
        <h1 className={`${styles.headingdark} mb-1`}>
          Outcomes and Opportunities
        </h1>
        <FAQCard
          question="What kind of projects will students complete during the course?"
          answer="Students will work on multiple projects ranging from simple games developed in Scratch to more complex projects using Unity or Unreal Engine, which they can add to their portfolios."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="Do you provide any certifications or assessments at the end of the course?"
          answer="Upon completion of the course, students can ask to receive a certificate of completion. We also provide assessments and feedback throughout the course to track progress."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="How can the skills learned in your courses benefit students in the future?"
          answer="Skills in game design and programming not only open pathways in the gaming industry but also foster problem-solving, creativity, and coding skills beneficial in various STEM fields."
        />
        <div className="border border-darkprimary mb-8" />
        <h1 className={`${styles.headingdark} mb-1`}>Additional Questions</h1>
        <FAQCard
          question="How can parents monitor their child's progress in the course?"
          answer="We provide regular updates to parents through our online portal and schedule parent-teacher meetings to discuss progress and feedback."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="Are there any technical requirements for participating in the online courses?"
          answer="Participants need a stable internet connection, a computer capable of running the software used in the course (e.g., Scratch, Unity), and a webcam for interactive sessions."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="Can students interact with their peers outside of class?"
          answer="Yes, we have a moderated online community where students can share their projects, get feedback, and collaborate with peers under supervision."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="Do you offer scholarships or financial aid for students?"
          answer="We believe in making education accessible to all. We offer scholarships and flexible payment plans for families in need. Please contact us for more information."
        />
        <div className="border border-darkprimary" />
        <FAQCard
          question="How can I get in touch if I have more questions?"
          answer="You can reach us through our website's contact form, email us directly, or call our support hotline. Our team is here to help with any inquiries you have."
        />
        <div className="border border-darkprimary" />
      </div>
    </div>
  );
};

const FAQ = () => {
  return (
    <div>
      <Accordion />
    </div>
  );
};

export default FAQ;
