import React, { useState } from "react";
import {
  foxtail,
  logowithclouds,
  menu,
  closemenu,
  kidoncomputer,
} from "../../assets/images";
import styles, { elements } from "../../pages/styles";
import { useEffect, useRef } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

const NavBar = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1060);
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
      setDropdownOpen(false);
    }
  };

  let Links = [
    // { name: "Programs", link: "/pages/events" },
    {
      name: "Classes",
      dropdown: [
        {
          name: "Game Design 🕹️",
          desc: "Transform your child's enthusiasm for video games into the creativity of designing their own!",
          link: "/pages/learnmore/gamedesign",
        },
        {
          name: "Coding 👾",
          desc: "Learn coding and programming skills while building unique apps, games, and websites!",
          link: "/pages/learnmore/coding",
        },
        {
          name: "3D Digital Art 🎨",
          desc: "Students animate their creative visions using skills in 3D art, including animation techniques, 3D sculpting, and more!",
          link: "/pages/learnmore/3dart",
        },
        {
          name: "Navigator 🚀",
          desc: "A personalized learning program for all abilities, tailored classes that spark students' interests in a captivating educational adventure.",
          link: "/pages/navigator",
        },
      ],
    },
    { name: "Events", link: "/pages/events" },
    { name: "About us", link: "/pages/about" },
    // { name: "Trial Class", link: "/pages/freetrial" },
    { name: "FAQs", link: "/pages/faq" },
    { name: "Contact Us", link: "/pages/contactus" },
  ];

  return (
    <div className={`w-full fixed top-0 left-0 z-40`}>
      <div className="flex justify-center items-center gap-4 w-full fixed top-0 left-0 py-2 bg-darkprimary z-50">
        <a
          href="/pages/contactus"
          className="hover:underline hover:underline-offset-1 hover:cursor-pointer decoration-white"
        >
          <h2 className={`${styles.paragraphwhite} text-center font`}>
            Summer Special: All Camps{" "}
            <span className="border-b-[3px] border-blueprimary">10% Off</span>!
          </h2>
        </a>
        {/* <div>
          <a
            href="/pages/freetrial"
            className="font-nunito bg-blueprimary p-2 hover:bg-orangeprimary rounded-full font-bold text-darkprimary focus:ring-4 focus:outline-none focus:ring-pinkprimary"
          >
            Schedule
          </a>
        </div> */}
      </div>
      <div className="bg-whiteprimary w-full fixed top-11 shadow-sm ">
        <div
          className={` ${
            open ? "bg-darkprimary" : ""
          } md:flex items-center md:py-0 py-6 justify-between max-w-[1600px] md:px-10 px-7 mx-auto relative `}
        >
          <div>
            <a href="/">
              <img
                src={logowithclouds}
                alt="Clouds"
                className="top-1 w-20 transition-all ease-in-out duration-300 absolute"
              />
            </a>
          </div>

          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-2 cursor-pointer md:hidden"
          >
            {open ? (
              <svg
                width="34px"
                viewBox="0 0 1024.00 1024.00"
                xmlns="http://www.w3.org/2000/svg"
                fill="#003959"
                stroke="#003959"
                strokeWidth="38.912"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#003959CCCCCC"
                  strokeWidth="6.144"
                />

                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#FFF7EB"
                    d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                  />
                </g>
              </svg>
            ) : (
              <svg
                width="34px"
                viewBox="0 0 20.00 20.00"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#003959"
                strokeWidth="1.16"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#CCCCCC"
                  strokeWidth="0.12"
                />

                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fill="#003959"
                    fillRule="evenodd"
                    d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
                  />{" "}
                </g>
              </svg>
            )}
          </div>

          <ul
            className={`md:shadow-none shadow-xl md:flex md:items-center md:pb-0 pb-2 pt-4 md:pt-0 absolute md:static md:bg-transparent rounded-b-3xl bg-tanprimary md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pr-2 md:transition-none transition-all duration-200 ease-in ${
              open ? "top-12 " : "top-[-550px]"
            }`}
            ref={menuRef}
          >
            <img
              src={foxtail}
              alt="Fox"
              className="md:hidden -scale-y-100 absolute right-2 top-5 w-14 duration-800 transition-all rotate-90 z-10"
            />
            <div
              className={`${
                !isDesktop ? "absolute grainybotmenu -z-10 rounded-b-3xl" : ""
              }`}
            ></div>
            {Links.map((link) =>
              link.dropdown ? (
                <li key={link.name} className="text-left ml-12 md:ml-0">
                  <button
                    href={link.link}
                    className={`${styles.paragraph} text-darkprimary md:mr-9 hover:md:text-blueprimary font-semibold gap-1 inline-flex items-center`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    {link.name}
                    <span className="ml-1">
                      {dropdownOpen ? <ChevronDown /> : <ChevronUp />}
                    </span>{" "}
                  </button>
                  {dropdownOpen && (
                    <div
                      className={`${styles.paragraph} md:absolute left-0 top-12 md:w-full md:rounded-md max-w-[1600px] md:bg-whiteprimary md:shadow-lg md:flex md:justify-between md:gap-5`}
                    >
                      <div className="md:flex md:flex-col md:items-center hidden w-1/4 my-4 justify-start pl-10">
                        <div className=" bg-white rounded-3xl p-4 flex flex-col items-center">
                          <img
                            src={kidoncomputer}
                            className="rounded-2xl mb-2"
                          />
                          <h2 className={`${styles.headingdark} text-center`}>
                            Classes
                          </h2>
                          <p className="text-center">
                            We offer a range of classes, each to spark the
                            unique interests of every student.
                          </p>
                        </div>
                      </div>
                      <ul className="md:grid-cols-2 md:grid gap-2 md:w-9/12 my-4">
                        {link.dropdown.map((dropdownLink) => (
                          <a
                            href={dropdownLink.link}
                            key={dropdownLink.name}
                            className=""
                            onClick={() => {
                              setOpen(false);
                              setDropdownOpen(false);
                            }}
                          >
                            <div className="mb-2 md:text-left md:p-2 border-2 border-transparent hover:md:border-pinkprimary md:min-h-40 rounded-xl hover:md:shadow-md">
                              <li>
                                <span className="md:font-semibold">
                                  {dropdownLink.name}
                                </span>
                                <br />
                                <span className="md:block hidden">
                                  {dropdownLink.desc}
                                </span>
                              </li>
                            </div>
                          </a>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ) : (
                <li
                  key={link.name}
                  className="text-left ml-12 md:ml-0 md:my-2.5 my-5"
                >
                  <a
                    href={link.link}
                    className={`${styles.paragraph} text-darkprimary font-semibold hover:md:text-blueprimary md:mr-9`}
                    onClick={() => setOpen(false)}
                  >
                    {link.name}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
