import React from "react";
import styles from "../../pages/styles";

const skills = [
  "Game Design",
  "Computer Basics",
  "3D Modelling",
  "2D Digital Art",
  "Computer Building",
  "Animation",
  "Programming",
  "Video Editing",
  "Website Development",
  "Microsoft Office",
  "Storytelling",
  "Graphic Design",
  "App Development",
  "Sound Design",
  "Creative Writing",
];

const NavigatorSkills = () => {
  return (
    <div className="bg-blueprimary mx-auto sm:pt-12 sm:pb-16 py-20 flex flex-col justify-center items-center sm:px-36 px-2">
      <div className={`${styles.headingdark} mb-4`}>Skills We Can Teach</div>
      <div className={`${styles.paragraph} mb-4 text-center`}>
        Our instructors have varied talents across a huge spread of topics
      </div>
      <div className="flex flex-wrap gap-5 mb-4 justify-evenly">
        {skills.map((skill, index) => (
          <Bubble key={index} text={skill} />
        ))}
      </div>
    </div>
  );
};

const Bubble = ({ text }) => {
  const style = {
    "--animation-duration-y": `${4 + Math.random() * 3}s`, // Between 2 and 5 seconds
    "--translate-y": `${3 + Math.random() * 20}px`, // Between 10px and 30px
  };

  return (
    <div
      className="bubble bg-orangeprimary rounded-full p-4 text-center shadow-lg"
      style={style}
    >
      <p className={`${styles.paragraph}`}>{text}</p>
    </div>
  );
};

export default NavigatorSkills;
