import React, { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { format, isPast, addDays, startOfWeek, nextDay, previousDay } from "date-fns";
import { db } from "../../components";
import { X } from "lucide-react";
import styles from "../styles";
import { logowithclouds } from "../../assets/images";

const formatDate = (date) => format(new Date(date), "MMMM d, yyyy");

const getNextOccurrence = (day) => {
  const today = new Date();
  const daysOfWeek = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0,
  };
  const nextOccurrence = nextDay(today, daysOfWeek[day.toLowerCase()]);
  return nextOccurrence;
};

const getPreviousOccurrence = (day) => {
  const today = new Date();
  const daysOfWeek = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0,
  };
  const previousOccurrence = previousDay(today, daysOfWeek[day.toLowerCase()]);
  return previousOccurrence;
};

const Events = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 6;

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      try {
        const eventsRef = collection(db, "publicEvents");
        const liveQuery = query(eventsRef, where("liveDate", "<=", new Date()), orderBy("liveDate"));
        const eventsSnapshot = await getDocs(liveQuery);
        const eventData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const closeOverlay = () => {
    setSelectedEvent(null);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getEventDate = (event) => {
    if (event.recurrence === "weekly" && event.recurrenceDay) {
      return getNextOccurrence(event.recurrenceDay);
    }
    return new Date(event.date);
  };

  const getPastEventDate = (event) => {
    if (event.recurrence === "weekly" && event.recurrenceDay) {
      return getPreviousOccurrence(event.recurrenceDay);
    }
    return new Date(event.date);
  };

  const upcomingEvents = events.filter((event) => !isPast(getEventDate(event)));
  const pastEvents = events.filter((event) => isPast(getPastEventDate(event)));

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = upcomingEvents.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(upcomingEvents.length / eventsPerPage);

  return (
    <div className="pb-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <div className="w-full flex justify-center mb-4">
            <img src={logowithclouds} className="size-[20%]" />
          </div>
          <h2 className={`${styles.headingdark} mb-4`}>We're Hosting Events!</h2>
          <p className={`${styles.paragraph} mt-2`}>
            Join us for fun and educational events designed to spark your child's interest in technology and programming. Don't miss out on the excitement!
          </p>
        </div>
        <div className="mx-auto mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-20">
          {isLoading ? (
            <p className={`${styles.paragraph} text-center`}>Loading...</p>
          ) : currentEvents.length === 0 ? (
            <p className={`${styles.paragraph} text-center text-gray-600`}>No upcoming events found, check back later!</p>
          ) : (
            currentEvents.map((event) => (
              <article key={event.id} className="flex flex-col items-start justify-between">
                <div
                  className="relative w-full cursor-pointer"
                  onClick={() => handleEventClick(event)}
                >
                  <img
                    alt=""
                    src={event.imageUrl}
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute" />
                  <div className="max-w-xl mt-4">
                    <div className="mt-2 flex items-center gap-x-4 text-xs">
                      <time dateTime={event.datetime} className={`${styles.paragraph} text-gray-500`}>
                        {formatDate(getEventDate(event))}
                      </time>
                    </div>
                    <div className="group relative">
                      <h3 className={`${styles.headingdark} mt-3 text-lg font-semibold leading-6 group-hover:text-gray-600`}>
                        {event.title}
                      </h3>
                      <div className={`${styles.paragraph} mt-5 line-clamp-3`}>
                        <div dangerouslySetInnerHTML={{ __html: event.description }} />
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))
          )}
        </div>
        {totalPages > 1 && (
          <div className="mt-8 flex justify-center">
            <ul className="inline-flex items-center -space-x-px">
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index}>
                  <button
                    onClick={() => paginate(index + 1)}
                    className={`px-3 py-2 border rounded-md ${
                      currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500"
                    }`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="mx-auto max-w-2xl text-center mt-16">
          <h3 className={`${styles.headingdark} mb-4`}>Past Events</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-20">
            {pastEvents.length === 0 ? (
              <p className={`${styles.paragraph} text-center text-gray-600`}>No past events available.</p>
            ) : (
              pastEvents.map((event) => (
                <article key={event.id} className="flex flex-col items-start justify-between">
                  <div
                    className="relative w-full cursor-pointer"
                    onClick={() => handleEventClick(event)}
                  >
                    <img
                      alt=""
                      src={event.imageUrl}
                      className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900" />
                  </div>
                  <div className="max-w-xl mt-4">
                    <div className="mt-2 flex items-center gap-x-4 text-xs">
                      <time dateTime={event.datetime} className={`${styles.paragraph} text-gray-500`}>
                        {formatDate(getPastEventDate(event))}
                      </time>
                    </div>
                    <div className="group relative">
                      <h3 className={`${styles.headingdark} mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600`}>
                        {event.title}
                      </h3>
                      <div className={`${styles.paragraph} mt-5 line-clamp-3`}>
                        <div dangerouslySetInnerHTML={{ __html: event.description }} />
                      </div>
                    </div>
                  </div>
                </article>
              ))
            )}
          </div>
        </div>
        {selectedEvent && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-2xl overflow-y-auto max-w-4xl max-h-[90%] relative">
              <button
                className="absolute top-4 right-4 text-lg rounded-full bg-darkprimary p-1 text-white"
                onClick={closeOverlay}
              >
                <X />
              </button>
              <div className="p-4">
                <img
                  src={selectedEvent.imageUrl}
                  alt={selectedEvent.title}
                  className="w-full max-h-60 object-cover rounded-lg mb-4"
                />
                <h2 className={`${styles.headingdark} mb-2`}>
                  {selectedEvent.title}
                </h2>
                <p className={`${styles.paragraph} mb-2`}>
                  <strong>Date:</strong> {formatDate(getEventDate(selectedEvent))}
                </p>
                <div
                  className={`${styles.paragraph} mb-4`}
                  dangerouslySetInnerHTML={{ __html: selectedEvent.description }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;
