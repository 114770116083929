import React from "react";
import styles from "../../../pages/styles";
import {
  aboutfox,
  aboutgamedesign,
  kidpointing,
  navigatorhero,
  thumbsupkid,
} from "../../../assets/images";

const About = ({ title, desc, img }) => {
  return (
    <div
      className={`flex md:flex-row items-center justify-evenly  flex-col rounded-[30px] -mt-4 sm:px-40 px-2 bg-blueprimary shadow-xl`}
    >
      <div className="flex flex-end w-[240px] max-h-[360px] md:order-last order-last -scale-x-100">
        <img src={navigatorhero} className="md:hidden block pt-0 " />
        <img src={aboutgamedesign} className="md:block hidden" />
      </div>
      <div className=" md:pt-0 pt-5 md:px-0 px-4 md:w-9/12 md:text-left text-center">
        <h1 className={`${styles.headingdark} mb-4`}>
          {title}
          <span className="underline decoration-pinkprimary decoration-8">
            Tech Tail
          </span>
          s
        </h1>
        <p className={`${styles.paragraph} `}>{desc}</p>
      </div>
    </div>
  );
};

export default About;

{
  /* <h1 className={`${styles.headingdark}`}>
{heading}
<span className="underline decoration-pinkprimary decoration-8">
  Tech Tail
</span>
s
</h1> */
}
