import React, { useState } from "react";
import useContactForm from "../hooks/useContactForm";
import InputField from "../UI/InputField";
import TextAreaField from "../UI/TextAreaField";
import styles from "../../../pages/styles";
import { aboutfox, starsalone } from "../../../assets/images";

const ContactUs = ({ rounded = false }) => {
  const { formData, handleChange, handleSubmit } = useContactForm();

  return (
    <div
      id="contactus"
      className={`${
        rounded && `lg:rounded-t-3xl `
      } flex flex-col bg-pinkprimary mt-0 md:pt-14 py-8 md:px-36 px-4 relative pb-14`}
    >
      <img
        src={starsalone}
        className="w-[5%] min-w-24 absolute left-5 top-[12%]"
      />

      <div className="w-full rounded-3xl bg-whiteprimary z-10 p-8 sm:px-14">
        {!formData.submitted ? (
          <form onSubmit={handleSubmit}>
            <h2 className={`${styles.headingdark} text-left mb-4`}>
              Contact Us
            </h2>
            <div className="grid grid-cols-2 gap-2 items-end">
              <InputField
                title="Parent/Guardian Name"
                name="parentname"
                value={formData.parentname}
                onChange={handleChange}
                placeholder="Jane Doe"
              />
              <InputField
                title="Phone Number"
                name="phone"
                type="number"
                value={formData.phone}
                onChange={handleChange}
                placeholder="(123) 456-7896"
              />
              <InputField
                title="Student Name"
                name="studentname"
                value={formData.studentname}
                onChange={handleChange}
                placeholder="Jack Doe"
              />
              <InputField
                title="Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="youremail@mail.com"
              />
              <div className="mb-4">
                <p className={`${styles.paragraph}`}>
                  Preferred Contact Method
                </p>
                <select
                  value={formData.contactpreference}
                  name="contactpreference"
                  onChange={handleChange}
                  className="p-2 px-4 rounded-xl w-full bg-white border-pinkprimary border-2 focus:border-orangeprimary focus:outline-none"
                  required
                >
                  <option value="" disabled defaultValue>
                    Please Choose
                  </option>
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                </select>
              </div>
              <div className="mb-4">
                <p className={`${styles.paragraph}`}>
                  How Did You Hear About Us?
                </p>
                <select
                  value={formData.referralSource}
                  name="referralSource"
                  onChange={handleChange}
                  className="p-2 px-4 rounded-xl w-full bg-white border-pinkprimary border-2 focus:border-orangeprimary focus:outline-none"
                  required
                >
                  <option value="" disabled>
                    Please Choose
                  </option>
                  <option value="friend">Friend</option>
                  <option value="internet">Internet Search</option>
                  <option value="socialmedia">Facebook or Social Media</option>
                  <option value="casemanager">Case Manager</option>
                  <option value="supportcoordinator">
                    Support Coordinator
                  </option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            {formData.referralSource === "other" && (
              <div className="mb-4">
                <InputField
                  title="What Introduced You to Us?"
                  name="referralName"
                  value={formData.referralName}
                  onChange={handleChange}
                  placeholder="I discovered you guys on the dinosaur channel"
                />
              </div>
            )}
            <TextAreaField
              title="Notes (Optional)"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="You can request free trial here or any other questions"
            />
            <input
              type="submit"
              value="Send Message"
              className="focus:outline-2 hover:outline-4 mt-5 rounded-3xl bg-darkprimary hover:bg-pinkprimary px-4 py-2 text-white font-bold w-full focus:bg-orangeprimary focus:border-pinkprimary focus:border-4"
            />
          </form>
        ) : (
          <div className="flex flex-col">
            <h1 className={`${styles.headingdark} text-center p-10 !pb-5`}>
              Thank you for contacting us will be back with you shortly!
              <div className="flex md:flex-row flex-col justify-center items-center gap-4">
                <img
                  src={aboutfox}
                  alt="techtails fox"
                  className="w-[30%] min-w-[300px] mt-4"
                />
                <a href="/#classes">
                  <button className="bg-pinkprimary text-whiteprimary hover:bg-orangeprimary border-b-8 border-orangeprimary hover:border-pinkprimary h-full py-4 px-6 rounded-xl">
                    See Class Options
                  </button>
                </a>
              </div>
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
