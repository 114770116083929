import React from "react";
import styles from "../../pages/styles";
import FreeTrial from "../Home/layout/FreeTrial";
import { zoomcall } from "../../assets/images";

const skills = [
  "Game Design",
  "3D Modelling",
  "Computer Building",
  "Animation",
  "Programming",
  "Video Editing",
  "Website Development",
  "Roblox Coding",
  "App Development",
  "Computer Basics",
  "Sound Design",
  "Minecraft Modding",
];

const FreeTrialSkills = () => {
  return (
    <div className="bg-blueprimary mx-auto sm:pt-12 sm:pb-12 py-10 flex flex-col justify-center items-center lg:px-36 px-2">
      <div className={`${styles.biggerheadingdark} mb-4 text-center`}>
        What We Teach
      </div>
      <div className={`${styles.paragraph} mb-4 text-center`}>
        We’re here to guide your child’s learning journey with expertise, offering courses that span across many topics.
      </div>
      <div className="flex md:flex-row flex-col gap-5 mb-4 justify-evenly items-center flex-shrink">
        <div>
          <img src={zoomcall} alt="Zoom Call" className="rounded-3xl" />
        </div>
        <div className="flex flex-wrap gap-4  mb-4 justify-evenly md:w-1/2 flex-grow">
          {skills.map((skill, index) => (
            <Bubble key={index} text={skill} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Bubble = ({ text }) => {
  const style = {
    "--animation-duration-y": `${4 + Math.random() * 3}s`, // Between 2 and 5 seconds
    "--translate-y": `${3 + Math.random() * 20}px`, // Between 10px and 30px
  };

  return (
    <div
      className="bubble bg-orangeprimary rounded-full p-4 text-center shadow-lg"
      style={style}
    >
      <p className={`${styles.paragraph}`}>{text}</p>
    </div>
  );
};

export default FreeTrialSkills;
