import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../components";
import { aboutfox } from "../../assets/images";
import styles from "../styles";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const PricingTool = () => {
  const [planType, setPlanType] = useState("group");
  const [hoursPerWeek, setHoursPerWeek] = useState(1);
  const [numberOfKids, setNumberOfKids] = useState(2);
  const [annualCommitment, setAnnualCommitment] = useState(false);
  const [parentName, setParentName] = useState("");
  const [childNames, setChildNames] = useState(["", ""]);
  const [submitted, setSubmitted] = useState(false);

  const groupRates = {
    1: 135,
    2: 245,
  };

  const privateRates = {
    1: 240,
    2: 460,
    3: 680,
  };

  const calculateGroupPricePerStudent = (hours, kids) => {
    let baseRate;
    if (kids === 2) {
      baseRate = 135;
    } else if (kids === 3) {
      baseRate = 90;
    } else if (kids === 4) {
      baseRate = 67.5;
    } else {
      baseRate = 135;
    }

    let price;
    if (hours === 1) {
      price = baseRate;
    } else if (hours === 2) {
      price = baseRate * 1.83;
    } else {
      price = baseRate * 1.83 + (hours - 2) * (baseRate * 0.75);
    }

    return Math.round(price / 5) * 5;
  };

  const calculateGroupPrice = (hours, kids) => {
    return calculateGroupPricePerStudent(hours, kids) * kids;
  };

  const calculatePrivatePrice = (hours) => {
    let baseRate = 0;

    if (hours <= 3) {
      baseRate = privateRates[hours] || 0;
    } else {
      baseRate = privateRates[3] + (hours - 3) * 220;
    }

    return Math.round(baseRate / 5) * 5;
  };

  const calculatePrice = () => {
    const price =
      planType === "group"
        ? calculateGroupPrice(hoursPerWeek, numberOfKids)
        : calculatePrivatePrice(hoursPerWeek);

    const discountedPrice = annualCommitment ? price - 15 : price;
    return { original: price, discounted: discountedPrice };
  };

  const handleChildNameChange = (index, value) => {
    const newChildNames = [...childNames];
    newChildNames[index] = value;
    setChildNames(newChildNames);
  };

  const handleNumberOfKidsChange = (e) => {
    const newNumberOfKids = Math.max(
      2,
      Math.min(4, parseInt(e.target.value, 10))
    );
    setNumberOfKids(newNumberOfKids);
    setChildNames(
      Array.from(
        { length: newNumberOfKids },
        (_, index) => childNames[index] || ""
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      planType,
      hoursPerWeek,
      numberOfKids,
      annualCommitment,
      parentName,
      childNames,
      totalPrice: calculatePrice().discounted,
      timestamp: serverTimestamp(),
    };

    try {
      await addDoc(collection(db, "classRequest"), formData);
      setSubmitted(true);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error submitting form. Please try again.");
    }

    console.log("Form Data:", formData);
  };

  const { original, discounted } = calculatePrice();

  const getLabelPosition = () => {
    if (hoursPerWeek === 1) {
      return "calc(0% + 30px)";
    }
    if (hoursPerWeek === 6) {
      return "calc(100% - 30px)";
    }
    return `calc(${((hoursPerWeek - 1) / 5) * 100}% - 15px)`;
  };

  return (
    <div className="flex flex-col items-center justify-center rounded-t-xl relative p-4 sm:p-8 overflow-hidden bg-whiteprimary shadow-lg -mt-4">
      <div className="absolute bg-blueprimary h-[600px] w-[600px] rounded-full -top-64 -left-64 opacity-30 z-0"></div>
      <div className="absolute bg-blueprimary h-[500px] w-[500px] rounded-full -bottom-64 -right-64 opacity-30 z-0"></div>
      <div className="absolute bg-pinkprimary h-[400px] w-[400px] rounded-full -top-32 -right-48 opacity-30 z-0"></div>
      <div className="absolute bg-pinkprimary h-[300px] w-[300px] rounded-full -bottom-32 -left-32 opacity-30 z-0"></div>

      <div className="flex flex-col w-full order-first relative z-10 text-center items-center mb-2">
        <h1 className={`${styles.headingdark}`}>Configure Your Pricing Plan</h1>
        <p className={`${styles.paragraph} `}>
          We provide this pricing tool so you can be as flexible as possible.
        </p>
      </div>

      <div className="w-full max-w-2xl relative z-10 mb-8 bg-whiteprimary p-4 sm:p-6 rounded-lg shadow-md">
        {!submitted ? (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col items-center mb-4">
              <label className={`${styles.paragraph} mb-2`}>
                Select Class Type:
              </label>
              <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 justify-center">
                {["group", "private"].map((type) => (
                  <label
                    key={type}
                    className={`flex items-center space-x-2 cursor-pointer relative ${
                      planType === type ? "text-pinkprimary" : ""
                    }`}
                    onClick={() => setPlanType(type)}
                  >
                    <span
                      className={`h-6 w-6 rounded-full ${
                        planType === type
                          ? "bg-pinkprimary border-pinkprimary"
                          : "bg-white border-pinkprimary"
                      } border-2 inline-flex items-center justify-center flex-shrink-0`}
                    >
                      {planType === type && (
                        <span className="block h-3 w-3 bg-white rounded-full"></span>
                      )}
                    </span>
                    <span className="relative">
                      <span className={`${styles.paragraph} capitalize`}>
                        {type} {type === "group" ? "Class" : "1:1 Class"}
                      </span>
                      {type === "private" && (
                        <span className="absolute bg-pinkprimary text-white text-xs px-2 py-1 rounded-md top-1/2 transform -translate-y-1/2 left-full ml-2 md:ml-4">
                          MOST POPULAR
                          <span className="absolute left-[-6px] top-1/2 transform -translate-y-1/2 w-0 h-0 border-r-[6px] border-r-pinkprimary border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent"></span>
                        </span>
                      )}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            {planType === "group" && (
              <p className={`${styles.paragraph} text-gray-500 text-md mb-4`}>
                * Group classes are for families or friends of up to four
                students signing up together.
              </p>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-8">
              <div className="col-span-1">
                <label className="block mb-2">
                  <span className={`${styles.paragraph}`}>Parent Name:</span>
                  <input
                    type="text"
                    placeholder="John Doe"
                    value={parentName}
                    onChange={(e) => setParentName(e.target.value)}
                    className="w-full p-2 border rounded mt-2 bg-white border-pinkprimary"
                    required
                  />
                </label>
              </div>

              <div className="col-span-1">
                <label className="block mb-2">
                  <span className={`${styles.paragraph}`}>
                    {planType === "group" ? "Number of Kids" : "Student Name"}:
                  </span>
                  {planType === "group" ? (
                    <input
                      type="number"
                      min="2"
                      max="4"
                      value={numberOfKids}
                      onChange={handleNumberOfKidsChange}
                      className="w-full p-2 border rounded mt-2 bg-white border-pinkprimary"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Jack Doe"
                      value={childNames[0]}
                      onChange={(e) => handleChildNameChange(0, e.target.value)}
                      className="w-full p-2 border rounded mt-2 bg-white border-pinkprimary"
                      required
                    />
                  )}
                </label>
              </div>

              {planType === "group" &&
                childNames.map((name, index) => (
                  <div key={index} className="col-span-1">
                    <label className="block mb-2">
                      <span className={`${styles.paragraph}`}>
                        {`Child ${index + 1}'s Name:`}
                      </span>
                      <input
                        type="text"
                        placeholder="Child Name"
                        value={name}
                        onChange={(e) =>
                          handleChildNameChange(index, e.target.value)
                        }
                        className="w-full p-2 border rounded mt-2 bg-white border-pinkprimary"
                        required
                      />
                    </label>
                  </div>
                ))}
            </div>

            <div className="flex flex-col items-center mb-8 w-full">
              <label className="block mb-10 w-full">
                <span className={`${styles.paragraph}`}>Hours per Week:</span>
                <p className={`${styles.paragraph} text-gray-500`}>
                  We provide weekly classes with monthly billing.
                </p>
              </label>
              <div className="relative flex flex-col items-center w-full px-6">
                <Slider
                  min={1}
                  max={6}
                  value={hoursPerWeek}
                  onChange={setHoursPerWeek}
                  trackStyle={{ backgroundColor: "#F290BB", height: 32 }}
                  handleStyle={{
                    borderColor: "#F290BB",
                    height: 36,
                    width: 36,
                    marginTop: -2,
                    backgroundColor: "#F290BB",
                  }}
                  railStyle={{ backgroundColor: "#E5E7EB", height: 32 }}
                />
                <div
                  className="absolute pointer-events-none"
                  style={{
                    left: getLabelPosition(),
                    bottom: "12px",
                    transform: "translateX(-50%)",
                    transition: "left 0.1s ease-out",
                  }}
                >
                  <div className="bg-pinkprimary text-white rounded-full px-3 py-1 text-sm mb-2 whitespace-nowrap">
                    {hoursPerWeek} hr{hoursPerWeek > 1 ? "s" : ""} / week
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-start mb-8 px-3">
              <label
                className="flex items-center space-x-2 cursor-pointer select-none"
                onClick={() => setAnnualCommitment(!annualCommitment)}
              >
                <span
                  className={`relative flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${
                    annualCommitment
                      ? "bg-pinkprimary border-pinkprimary"
                      : "bg-white border-pinkprimary"
                  }`}
                >
                  {annualCommitment && (
                    <span className="block w-3 h-3 bg-white rounded-full"></span>
                  )}
                </span>
                <span
                  className={`${styles.paragraph} text-md px-2 py-1 rounded ${
                    annualCommitment
                      ? "bg-pinkprimary text-white"
                      : "bg-white text-pinkprimary border border-pinkprimary"
                  }`}
                >
                  Annual Commitment ($15 discount per month)
                </span>
              </label>
            </div>

            <div className="text-center mb-8">
              {planType === "group" ? (
                <>
                  <h3 className={`${styles.headingdark}`}>
                    Cost per Student:{" "}
                    {annualCommitment ? (
                      <>
                        <s>
                          $
                          {calculateGroupPricePerStudent(
                            hoursPerWeek,
                            numberOfKids
                          ).toFixed(2)}
                        </s>{" "}
                        $
                        {(
                          calculateGroupPricePerStudent(
                            hoursPerWeek,
                            numberOfKids
                          ) -
                          15 / numberOfKids
                        ).toFixed(2)}
                      </>
                    ) : (
                      `$${calculateGroupPricePerStudent(
                        hoursPerWeek,
                        numberOfKids
                      ).toFixed(2)}`
                    )}
                  </h3>
                  <p className={`${styles.paragraph} text-gray-600`}>
                    (Total Monthly: $
                    {annualCommitment ? (
                      <>
                        <s>{original.toFixed(2)}</s> ${discounted.toFixed(2)}
                      </>
                    ) : (
                      original.toFixed(2)
                    )}
                    )
                  </p>
                </>
              ) : (
                <h3 className={`${styles.headingdark}`}>
                  Total Monthly Cost: $
                  {annualCommitment ? (
                    <>
                      <s>{original.toFixed(2)}</s> ${discounted.toFixed(2)}
                    </>
                  ) : (
                    original.toFixed(2)
                  )}
                </h3>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-darkprimary text-white py-2 px-4 rounded hover:bg-pinkprimary"
              >
                Send Request for Class
              </button>
            </div>
          </form>
        ) : (
          <div className="flex flex-col items-center">
            <h1 className={`${styles.headingdark} text-center p-10`}>
              Thank you for submitting your class request!
            </h1>
            <img
              src={aboutfox}
              alt="TechTails fox"
              className="w-[30%] min-w-[300px] mt-4"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingTool;
