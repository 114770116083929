import React from "react";
import {
  FAQ,
  ImageText,
  NavigatorAbout,
  NavigatorPricing,
  NavigatorSkills,
  PricingClasses,
  TechRequiredClasses,
} from "../../components";
import { inclusivityhero, navigatorhero } from "../../assets/images";
import { Helmet } from "react-helmet";

const Inclusivity = () => {
  const ideas = ["3D Modelling", "Game Design", "more"];

  return (
    <div>
      <Helmet>
        <title>Navigator Program: Tech Tails Private Virtual Classes</title>
        <meta
          name="description"
          content="A Tech Tails Program designed for individuals of all abilities, with trained instructors and personalized classes designed to align with each student's interests, fostering a unique and engaging learning experience."
        />
        <meta
          name="keywords"
          content="Tech Tails, Navigator, virtual tech classes, learn technology, Salt Lake City tech, personalized tech learning, special needs classes"
        />
      </Helmet>
      <ImageText
        title="Navigator"
        desc="A Tech Tails Program designed for individuals of all abilities, with trained instructors and personalized classes designed to align with each student's interests, fostering a unique and engaging learning experience."
        //   bgcolor="bg-pinkprimary"
        img={navigatorhero}
      />
      <NavigatorAbout />
      <NavigatorSkills ideas={ideas} />
      <FAQ />
      <NavigatorPricing
        title="Navigator Pricing"
        desc="🚀 Our pricing is discounted as we enter the early stages of welcoming new students beyond friends and family."
      />
      <TechRequiredClasses />
    </div>
  );
};

export default Inclusivity;

// Explorer Program
// Adventurer Program
// Onward Program
// Navigator Program
// Odyssey Program
// Quest Program
// Discover Program
// Pathfinder Program
