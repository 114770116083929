import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Shared/firebase";

const useOnboardingForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitForm = async (formData) => {
    setIsLoading(true);
    try {
      // Specify the collection name in Firestore
      const docRef = await addDoc(collection(db, "onboardingForms"), formData);
      setIsLoading(false);
      return docRef.id; // Return the document ID
    } catch (e) {
      console.error("Error adding document: ", e);
      setError(e);
      setIsLoading(false);
      return null; // In case of error
    }
  };

  return { isLoading, error, submitForm };
};

export default useOnboardingForm;
