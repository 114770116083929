import React from "react";
import styles from "../../pages/styles";
import { foxtail, textlogo } from "../../assets/images";

const HeroClasses = ({ title, tagline, desc, img }) => {
  return (
    <div
      className="flex md:flex-row flex-col justify-center columns-2 items-center gap-4 mx-5
     basis-2/4 relative -z-10 md:py-12"
    >
      <div className="basis-2/5">
        <div className="flex md:justify-start justify-center">
          <img alt="Tech Tails" src={textlogo} className="w-40 mb-6 " />
        </div>
        <h1
          className={`${styles.biggerheadingdark} md:text-left text-center mb-4`}
        >
          {title}
        </h1>{" "}
        <h1 className={`${styles.headingdark} mb-4 md:font-bold font-normal`}>
          {tagline}
        </h1>{" "}
        <p className={`${styles.paragraph}`}>{desc}</p>
      </div>
      <div className="spinner">
        <img
          src={img}
          alt="loading..."
          className="w-[100%] max-w-[560px] rounded-3xl px-2 float mb-7"
        />
      </div>
      <img
        src={foxtail}
        alt="Tech Tails Fox"
        className=" absolute -bottom-5 sm:right-16 md:left-auto left-1 w-[100px]"
      />
    </div>
  );
};

export default HeroClasses;
