import React from "react";
import styles from "../../pages/styles";
import {
  facebook,
  instagram,
  logowithclouds,
} from "../../assets/images/index.js";

const Footer = () => {
  return (
    <div
      className={`${styles.paragraphwhite} flex-col bg-darkprimary p-12 xl:rounded-t-3xl`}
    >
      <div className="sm:grid sm:grid-cols-3 flex flex-col  mb-12 text-center gap-y-4 sm:gap-x-10">
        <div className="flex mb-4 -ml-4 justify-center">
          <a href="/">
            <img src={logowithclouds} className="w-44 ml-4" />
          </a>
        </div>
        <div className="sm:grid gap-8 sm:grid-cols-2 flex flex-col gap-y-4 flex-1">
          <a href="/pages/privacypolicy" className="hover:text-pinkprimary">
            Privacy Policy
          </a>
          <a href="/pages/termsofservice" className="hover:text-pinkprimary">
            Terms of Service
          </a>
          <a href="tel:801" className="hover:text-pinkprimary">
            Phone: (801) 704-7878
          </a>
          <a
            href="mailto:teacher@techtails.org"
            className="hover:text-pinkprimary"
          >
            Email: teacher@techtails.org
          </a>
        </div>
        <div className="flex gap-8 justify-center order-last">
          <a href="https://www.instagram.com/techtails.teaching" className="">
            <img src={instagram} className="w-10 h-10" />
          </a>
          <a
            href="https://www.facebook.com/techtails.teaching"
            className="flex justify-center"
          >
            <img src={facebook} className="w-10 h-10" />
          </a>
        </div>
      </div>
      <div></div>
      <div className="flex justify-center">
        <p className="text-center mb-4">
          Tech Tails 1878 W 12600 S, RIVERTON, UT 84065
        </p>
      </div>
      <div className="flex justify-center">
        <p>© 2024 TechTails, LLC.</p>
      </div>
    </div>
  );
};

export default Footer;
