import React from "react";
import {
  About,
  FAQ,
  Hero,
  ContactUs,
  OurApproach,
  OurClasses,
  TryBefore,
  EmailDiscount,
  FreeTrialAbout,
  FreeTrialHero,
  FreeTrialSkills,
  FreeTrialPrograms,
} from "../../components/index";
import { Helmet } from "react-helmet";
import { freetrialhero } from "../../assets/images";

const AboutUs = () => {
  return (
    <div>
      {" "}
      <Helmet>
        <title>
          About Tech Tails: Personalized Tech Education from Salt Lake City
        </title>
        <meta
          name="description"
          content="Embark on an epic tech learning adventure with Tech Tails. Based in Salt Lake City, Utah, we bring engaging, personalized tech education to your doorstep. Unlock your potential and explore the world of technology with our virtual classes. Join the community of future tech wizards today!"
        />
        <meta
          name="keywords"
          content="Tech Tails, about techtails, tech education, virtual tech classes, learn technology, Salt Lake City tech, personalized tech learning, tech wizards"
        />
      </Helmet>
      <Hero />
      <About
        title="About "
        desc="We're Tech Tails from Salt Lake City, Utah 🏔️, we bring the excitement of tech learning right into your home with our virtual classes. Our mission? To make tech learning fun and personal! Think of us as your remote tech guides 🚀. We try to find each individuals unique spark and help them explore the amazing world of technology."
      />
      <OurApproach />
      <ContactUs />
      <FAQ />
    </div>
  );
};

export default AboutUs;
