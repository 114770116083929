import React, { useEffect, useState } from "react";
import styles from "../../../pages/styles";
import { aboutgamedesign, logowithclouds } from "../../../assets/images";
import { Gift, X } from "lucide-react";
import { db } from "../../Shared/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export default function EmailDiscount() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [closedButton, setClosedButton] = useState(false);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false); // New state variable for submission status

  useEffect(() => {
    const closedAutoOverlay = localStorage.getItem("closedAutoOverlay");
    if (!closedAutoOverlay) {
      const timer = setTimeout(() => {
        setShowOverlay(true);
        setIsVisible(true);
        disableScroll();
      }, 12000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(!closedButton && window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [closedButton]); // Depend on `closedButton` to re-run when its state changes

  const disableScroll = () => (document.body.style.overflow = "hidden");
  const enableScroll = () => (document.body.style.overflow = "visible");

  const handleOpenOverlay = () => {
    setShowOverlay(true);
    setIsVisible(true);
    disableScroll();
  };

  const handleCloseOverlay = () => {
    setIsVisible(false);
    localStorage.setItem("closedAutoOverlay", "true");
    setTimeout(() => {
      setShowOverlay(false);
      enableScroll();
    });
  };

  const handleCloseButton = () => {
    setClosedButton(true);
    setShowButton(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() !== "") {
      try {
        await addDoc(collection(db, "emailSignups"), {
          email: email.trim(),
          timestamp: serverTimestamp(),
          contacted: false,
        });
        console.log("Email submitted successfully");
        setEmail("");
        setSubmitted(true); // Set submission status to true
      } catch (error) {
        console.error("Error adding email: ", error);
      }
    }
  };

  return (
    <>
      <div
        className={`${
          styles.paragraph
        } fixed bottom-5 left-5 z-30 transition-opacity duration-400 ${
          showButton
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <button
          onClick={handleOpenOverlay}
          className="bg-darkprimary hover:bg-orangeprimary text-white py-3 px-4 shadow-lg rounded-xl flex items-center justify-center space-x-2"
        >
          <Gift className="w-5 h-5" />
          <span>10% Off First Class</span>
        </button>
        <button
          onClick={handleCloseButton}
          className={`${styles.paragraph} absolute -top-3 p-1 -left-3 bg-red-400 hover:bg-red-700 text-white text-md font-bold rounded-full`}
        >
          <X className="w-5 h-5" />
        </button>
      </div>

      {showOverlay && (
        <div
          id="overlay"
          className={`fixed z-50 inset-0 transition-opacity duration-1000 ${
            isVisible ? "opacity-100 bg-white bg-opacity-50" : "opacity-0"
          }`}
          onClick={handleCloseOverlay}
        >
          <div
            className="md:gap-10 relative md:top-20 top-0 max-w-[1100px] mx-auto px-2 md:w-9/12  shadow-lg rounded-lg bg-whiteprimary flex md:flex-row items-center justify-center flex-col"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-end sm:w-[350px] w-[200px] md:order-first order-last">
              <img src={aboutgamedesign} alt="Kid holding laptop" />
            </div>
            <div className="my-auto flex flex-col items-center md:pt-0 pt-8 md:w-1/2 px-0">
              <img src={logowithclouds} alt="Tech Tails" className="w-36" />
              <div className={`${styles.paragraph} mt-5 text-center`}>
                <h3 className={`${styles.headingdark} mb-2`}>
                  Welcome to Tech Tails 🚀
                  <br /> Get 10% Off First Class
                </h3>
                {submitted ? (
                  <div className="">
                    <p>
                      Thanks for joining the Tech Tails Family! Use this code:
                    </p>
                    <div className="mt-2 flex justify-center items-center py-2 px-1 w-full bg-white rounded-2xl text-pinkprimary">
                      kidslearncode2024
                    </div>
                    <button onClick={handleCloseOverlay} className="mt-4">
                      <a
                        href="/#classes"
                        className="py-2 px-8 bg-pinkprimary hover:bg-orangeprimary text-white rounded-2xl"
                      >
                        Explore Classes
                      </a>
                    </button>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <p>
                      We'd love to stay in touch for special offers and our Tech
                      Tails newsletter!
                    </p>
                    <div className="mt-2 py-1 flex items-center">
                      <input
                        type="email"
                        className="mt-2 p-2 w-full rounded-md border-pinkprimary border-2 focus:outline-none focus:border-orangeprimary"
                        placeholder="Your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="flex py-3 justify-end">
                      <div className="flex flex-col items-center">
                        <button
                          type="submit"
                          className="w-full px-20 py-3 bg-darkprimary text-white text-base font-medium rounded-md shadow-sm hover:bg-orangeprimary focus:outline-none focus:ring-2 focus:ring-pinkprimary"
                        >
                          Submit
                        </button>
                        <a
                          onClick={handleCloseOverlay}
                          className={`${styles.paragraph} mt-1 text-sm leading-3 cursor-pointer`}
                        >
                          No, thanks
                        </a>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="absolute top-3 right-3 p-2">
              <button
                onClick={handleCloseOverlay}
                className={`${styles.paragraph} bg-red-400 hover:bg-red-500 p-1 text-white font-bold rounded-full`}
              >
                <X />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
