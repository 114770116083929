import React from "react";
import { logowithclouds } from "../../assets/images";
import styles, { elements } from "../styles";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <div className="w-full overflow-hidden">
      <div className={`${styles.flexStart}`}>
        <div className="h-screen flex flex-col items-center justify-center py-48">
          <img src={logowithclouds} className="w-1/2 mb-15" />
          <h1 className={`${styles.heading2}`}>
            Oops, sorry the page you've reached doesn't exist!
          </h1>
          <Link to="/">
            <button className={`${elements.bluebutton}`}>Return To Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
