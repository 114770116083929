import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  NoPage,
  CodingClass,
  FreeTrialPage,
  DArtClass,
  GameDesignClass,
  StudentOnboard,
  PrivacyPolicy,
  TOS,
  Inclusivity,
  FAQ,
  ContactUsPage,
  AboutUs,
  Events,
} from "./pages";
import { ScrollToAnchor } from "./components";
import { ParallaxProvider } from "react-scroll-parallax";
import { Heading, NavBar, Footer } from "./components";
import TrialForm from "./pages/techtails/TrialForm";
import ZoomHowTo from "./pages/techtails/ZoomHowTo";
import PricingTool from "./pages/techtails/PricingTool";

const App = () => (
  <ParallaxProvider>
    <div className="w-full overflow-hidden">
      <NavBar />
      <div className="flex flex-col items-center">
        <Heading />
        <div className="max-w-screen-lg w-full pt-[108px]">
          <ScrollToAnchor />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pages/zoomhowto" element={<ZoomHowTo />} />
            <Route path="/pages/pricingtool" element={<PricingTool />} />
            <Route path="/pages/freetrial" element={<FreeTrialPage />} />
            <Route path="/pages/contactus" element={<ContactUsPage />} />
            <Route path="/pages/about" element={<AboutUs />} />
            <Route path="/pages/events" element={<Events />} />
            <Route path="/pages/FAQ" element={<FAQ />} />
            <Route path="/pages/learnmore/coding" element={<CodingClass />} />
            <Route path="/pages/learnmore/3dart" element={<DArtClass />} />
            <Route path="/pages/navigator" element={<Inclusivity />} />
            <Route path="/pages/onboarding" element={<StudentOnboard />} />
            <Route path="/pages/trialform" element={<TrialForm />} />
            <Route path="/pages/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/pages/termsofservice" element={<TOS />} />
            <Route
              path="/pages/learnmore/gamedesign"
              element={<GameDesignClass />}
            />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  </ParallaxProvider>
);

export default App;
