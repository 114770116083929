import React from "react";
import styles from "../styles";

const TOS = () => {
  return (
    <div className={`${styles.paragraph} container mx-auto p-4 px-10`}>
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Introduction</h2>
        These Terms and Conditions apply to the Tech Tails, LLC (“Tech Tails”)
        online platform offered at{" "}
        <a href="/" className="text-pinkprimary hover:text-orangeprimary">
          www.techtails.org
        </a>{" "}
        and all lessons, classes, courses, and options offered by Tech Tails
        (“Courses” and altogether with the website, the “Services”).
        <br /> By using the Services, registering you or your Enrollee for a
        Course, and/or affirmatively giving your agreement, you are agreeing on
        your own behalf and that of your Enrollee to abide and be bound by the
        Privacy Policy found here and the Terms and Conditions contained and
        referenced herein.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Online Courses</h2>
        At Tech Tails, we provide a multitude of online coding courses geared
        toward children. These Courses are primarily aimed at game design, but
        we also offer courses in robotics, art, and generalized coding. If you
        are purchasing, or your Enrollee is participating in, an online Course,
        you agree on your own behalf and on behalf of your Enrollee to the
        following:
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Course Offerings</h2>
        We offer a variety of courses in many subjects. However, we do not
        guarantee the availability of or space in any individual course. Only
        Enrollees who are registered for the Course may participate – you agree
        that you will not allow more than one Enrollee to use an Enrollee
        account at a time to simultaneously participate in a Course without
        separate registrations. Courses, unless specifically noted in the Course
        description, are only taught in English. <br />
        For security, we may video record each Course session. These recordings
        are taken solely for purposes of improving the product, and we do not
        provide copies of these recordings to anybody in order to protect the
        privacy of all the Enrollees. We may, in extremely limited situations,
        provide the videos to law enforcement if we believe that any Enrollees
        are at risk or pose a risk to others. While the Courses are generally
        open to all learners, we reserve the right to refuse enrollment for any
        Enrollee for any reason, including but not limited to, past disruptive
        behavior or nonpayment of Course fees.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Payment and Fees</h2>
        Payment to participate in any of our Courses is due at the time of
        sign-up or as set out in the checkout for the Course and is
        non-transferable or refundable except in our discretion. Please contact
        us if you have any payment problems or you wish to make changes to your
        Course enrollment. In no event will Course fees be refunded after the
        start of the Course. If you elect to make periodic payments, if
        available, you authorize Tech Tails to auto-charge your card on file for
        the amounts due per your payment schedule. If for any reason a payment
        is not made by the due date, you will have 5 business days from the due
        date to bring the account current, or your Enrollee will be removed from
        the course until the account is brought current, and you may be charged
        a late fee. No refunds, credits, or make-up classes will be provided if
        a session is missed due to a delinquent payment. <br />
        In the event we offer a promotional discount, such discounts are limited
        to one discount per Enrollee. There may be other limitations as to how
        they apply, and codes must be submitted at the time of registration.
        Tech Tails will not honor retroactive adjustments, and the total
        discounts received cannot exceed the total cost of the products
        purchased.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">
          Equipment and other Add-Ons
        </h2>
        For all online Courses, Enrollees must have their own computer and
        related equipment. You are responsible for procuring all such equipment
        and we do not guarantee or make any warranties about your ability to
        access the Services using your equipment. Please see our minimum
        requirements to understand whether your personal equipment is sufficient
        for full enjoyment of the Course. Where in-person classes are offered,
        Enrollees will be provided a workstation.{" "}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Code of Conduct</h2>
        To promote the best learning environment possible, all Enrollees and
        parents will be held to this Code of Conduct. Failure to comply with
        this Code of Conduct or engaging in actions or attitudes that seem to be
        harmful to the atmosphere, other participants, or staff, in the opinion
        of Tech Tails or its instructors can lead to removal from a Course or
        Course(s). Tech Tails reserves the right to dismiss Enrollees from a
        Course and prevent an Enrollee from attending additional programs
        without any prior warning for (1) violating any of the terms of this
        code of conduct, or (2) if Tech Tails determines that a Course is not a
        suitable and/or productive environment for an Enrollee (this includes
        incidents in which an Enrollee does not have sufficient English language
        skills to participate in the Course; participation in courses usually
        requires a high level of English understanding). Refunds will not be
        given for Enrollees dismissed for failure of the Enrollee or the parent
        to abide by the Code of Conduct, or if it is determined that a Course is
        not suitable for an Enrollee. While Tech Tails strives to maintain
        excellent relationships with Enrollees, in some rare cases, we may
        determine that Tech Tails is not a compatible environment for every
        Enrollee.{" "}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Special Accommodations</h2>
        We believe that all children should have the opportunity to learn. If
        your Enrollee requires a special accommodation to participate in a Tech
        Tails Course, please contact us using the information below no less than
        two weeks prior to your Enrollee’s first day of the Course to arrange
        accommodations. We will make what reasonable accommodations that we are
        able to, but we do not guarantee that we will be able to meet your
        requirements.{" "}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Reservation Changes</h2>
        Changes to your Enrollee’s Courses may be accomplished contacting us.
        However, we cannot guarantee that change requests can be honored. Please
        review Course cancellation/change policies listed in the Course
        description.{" "}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Ownership</h2>
        The Tech Tails website, Courses, and related Services belong in their
        entirety to Tech Tails. No right, title, or interest in any of the
        Services or the underlying intellectual property is granted to you or
        any Enrollee by virtue of participation in a Tech Tails Course. You and
        your Enrollee agree to uphold the copyright and trademark rights of Tech
        Tails, their partners, and any company whose products are used at a Tech
        Tails Course.{" "}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">General Releases</h2>
        As a condition of participation, and subject to our privacy practices,
        you authorize Tech Tails and its partners to take photos, videos,
        images, audio, and testimonials of you and your Enrollee and agree that
        said content may be used by Tech Tails in promotional materials,
        marketing collateral, and online media. These images, testimonials,
        photos, videos, and audio may be shared and used by corporate partners,
        the media, or other organizations that work with Tech Tails. You also
        agree that all projects and work created by your Enrollee during a Tech
        Tails Course may be used by Tech Tails in promotional materials, online,
        and other print media, and may be shared and used by corporate partners,
        the media, or other organizations that work with Tech Tails. You
        understand that Tech Tails, its owners, agents, partners, facility
        providers, and employees will not be held liable for damages and
        injuries associated with the use of any content released herein,
        including any and all claims based on negligence. You agree that all
        images, testimonials, photos, video, and audio taken at or in connection
        with a Tech Tails Course are the sole and exclusive property of Tech
        Tails, and that Tech Tails has a royalty-free, perpetual license to use
        copies of all Enrollee work and projects created at a Tech Tails Course.{" "}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">
          Name and Likeness Release
        </h2>
        As a condition of participation and subject to our privacy practices,
        you authorize Tech Tails and the press to use your Enrollee’s full name
        and likeness in print, radio, TV, and other mediums.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Software Accounts</h2>
        Some Tech Tails Course activities require creation and/or use of an
        online account or require an online account to be created for your
        Enrollee. You consent to create or have Tech Tails create account(s) as
        needed for your Enrollee to participate in Course activities. During
        non-instructional time, Enrollees may have access to websites that
        require accounts to be set up. While it is against Tech Tails rules for
        Enrollees to set up accounts without their instructor’s permission,
        there may be instances where an Enrollee may create an account without
        the knowledge of Tech Tails or its employees. In such instances, you
        release Tech Tails and its employees from any and all responsibility and
        liability for accounts created by your Enrollee without Tech Tails’s
        knowledge.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Software Accounts</h2>
        Tech Tails takes its corporate responsibility and Tech Tails family
        values very seriously. However, we cannot guarantee that younger
        Enrollees at Tech Tails will avoid all contact with or mention of games
        rated “T” for Teen, or “M” for Mature. Tech Tails will make a concerted
        effort to minimize both direct and indirect exposure to any games not
        rated for an Enrollee’s age group. Students attending courses designed
        for older ages have a greater chance of being exposed to materials rated
        for that older age group. If an Enrollee is attending a course for ages
        13+, they may be exposed to games rated “M” for Mature by the ESRB
        (Entertainment Software Rating Board). You voluntarily assume any and
        all risks, known or unknown, associated with your Enrollee’s exposure to
        game content at a Tech Tails Course.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Gaming Ratings</h2>
        Tech Tails takes its corporate responsibility and Tech Tails family
        values very seriously. However, we cannot guarantee that younger
        Enrollees at Tech Tails will avoid all contact with or mention of games
        rated “T” for Teen, or “M” for Mature. Tech Tails will make a concerted
        effort to minimize both direct and indirect exposure to any games not
        rated for an Enrollee’s age group. Students attending courses designed
        for older ages have a greater chance of being exposed to materials rated
        for that older age group. If an Enrollee is attending a course for ages
        13+, they may be exposed to games rated “M” for Mature by the ESRB
        (Entertainment Software Rating Board). You voluntarily assume any and
        all risks, known or unknown, associated with your Enrollee’s exposure to
        game content at a Tech Tails Course.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Liability</h2>
        ON BEHALF OF YOUR SON/DAUGHTER/WARD, YOU, THE PARENT/GUARDIAN, IN
        EXCHANGE FOR THE RIGHT OF MY SON/DAUGHTER/WARD TO PARTICIPATE IN TECH
        TAILS PROGRAM(S), HEREBY RELEASE TECH TAILS, LLC ITS OWNERS,
        INSTRUCTORS, AGENTS, PARTNERS, FACILITY PROVIDERS AND EMPLOYEES FROM
        LIABILITY (INCLUDING CLAIMS BASED UPON NEGLIGENCE) FOR ANY AND ALL
        DAMAGES OR INJURIES TO YOUR SON/DAUGHTER/WARD OR DAMAGE OF ANY PERSONAL
        PROPERTY. YOU AGREE TO BE FULLY RESPONSIBLE FOR ANY AND ALL SUCH DAMAGES
        OR INJURIES WHICH MAY RESULT DIRECTLY OR INDIRECTLY FROM ANY NEGLIGENT
        ACTS OR ACTIVITIES ASSOCIATED WITH TECH TAILS OR THE COURSES. HOWEVER, I
        UNDERSTAND THAT I AM NOT RELEASING TECH TAILS, ITS OWNERS, AGENTS,
        PARTNERS, FACILITY PROVIDERS, AND EMPLOYEES FROM GROSS NEGLIGENCE,
        RECKLESS CONDUCT OR INTENTIONALLY TORTIOUS CONDUCT. TO THE EXTENT THIS
        RELEASE CONFLICTS WITH STATE/PROVINCIAL LAW GOVERNING RELEASES, THIS
        RELEASE IS TO BE GIVEN THE FULLEST FORCE AND EFFECT PERMITTED UNDER
        STATE/PROVINCIAL LAW. SHOULD ANY PART OF THIS CONTRACT BE FOUND INVALID
        OR NOT ENFORCEABLE BY A COURT OF LAW, THEN THE REMAINING PORTION SHALL
        CONTINUE TO BE VALID AND IN FORCE.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Indemnification</h2>
        You agree to defend, indemnify, and hold Tech Tails, its officers,
        directors, employees, and agents, harmless from and against any claims,
        liabilities, damages, losses, and expenses, including, without
        limitation, reasonable attorneys’ fees and costs, arising out of or in
        any way connected with your Enrollee’s participation in a Tech Tails
        Course.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Arbitration Agreement</h2>
        You agree that any dispute other than collection matters, arising out of
        or relating to this Agreement, you or your Enrollee’s participation in a
        Course with Tech Tails, or otherwise arising between the parties,
        including, without limitation, any statutorily created or protected
        rights, as permitted by applicable state/provincial or federal laws,
        shall be settled by arbitration to be held in [Jurisdiction] in
        accordance with the Commercial Rules of the American Arbitration
        Association, and judgment upon the award rendered by the arbitrator(s)
        may be entered in any court of competent jurisdiction. The prevailing
        party in the arbitration shall be entitled to recover expenses including
        costs and reasonable attorney’s fees associated therewith. Should any
        part of this contract be found invalid or not enforceable by a court of
        law, then the remaining portion shall continue to be valid and in force.
        You hereby acknowledge that you understand the terms of this ARBITRATION
        AGREEMENT, and you agree to comply with all of its terms and provisions.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Rights Reserved</h2>
        Tech Tails reserves the right to update or modify these Terms and
        Conditions at any time. Tech Tails is not a university-sponsored
        program. Tech Tails reserves the right to cancel or modify any and all
        classes, components of the website or Services, or Courses for any
        reason.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">SMS Terms of Service</h2>
        <ul className="list-disc pl-5">
          <li>
            Tech Tails will allow an opportunity to opt into messaging by
            entering a phone number into our contact form.
          </li>
          <li>You can cancel the Service at any time. Just text “STOP”.</li>
          <li>
            If at any time you forget what keywords are supported, just text
            “HELP” and our team will call you.
          </li>
          <li>We are able to deliver messages to all major phone carriers.</li>
          <li>
            Message and data rates may apply for any messages sent to you from
            us and to us from you. Tech Tails is not liable for the cost of any
            such messages. You will receive recurring messages. If you have any
            questions about your text plan or data plan, it is best to contact
            your wireless provider. For all questions about the service provided
            by this short code, you can send an email to [Contact Email].
          </li>
          <li>
            You understand that anyone with access to your mobile phone may be
            able to view the messages you receive when using the Service, and
            you agree that Tech Tails will not be liable to you if this occurs.
          </li>
          <li>
            You understand that you are not required to consent to the Service
            to receive any other services from Tech Tails.
          </li>
          <li>
            By agreeing to these Terms of Service and providing us with your
            mobile phone number when you opt in to the Service, you authorize
            Tech Tails to contact you by text message at your mobile phone
            number using an automatic telephone dialing system or device, or any
            other computer assisted technology as applicable.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <p>
          If you have any questions regarding privacy, please read our{" "}
          <a
            href="/pages/privacypolicy"
            className="text-pinkprimary hover:text-orangeprimary"
          >
            Privacy Policy
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default TOS;
