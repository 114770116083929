const styles = {
  boxWidth: "md:max-w-[1580px] w-full", //important to have that nice responsive affect to increase page width

  heading2:
    "font-nunito text-darkprimary text-center mb-5 font-bold sm:text-xl text-2xl",
  headingdark: "font-nunito text-darkprimary sm:text-2xl text-3xl font-bold",
  biggerheadingdark:
    "font-nunito text-darkprimary sm:text-4xl text-4xl font-extrabold mb-2",
  biggerheadingwhite:
    "font-nunito text-whiteprimary sm:text-4xl text-4xl font-extrabold mb-2",
  headingwhite:
    "font-nunito text-whiteprimary sm:text-2xl text-3xl font-bold mb-3",
  paragraph:
    "font-poppins font-normal text-darkprimary text-[18px] leading-[30.8px]",
  paragraphwhite:
    "font-poppins font-normal text-whiteprimary text-[18px] leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
};

export const elements = {
  bluebutton: `font-nunito bg-darkprimary hover:bg-pinkprimary rounded-full sm:py-3 sm:px-9 py-3 px-6 font-bold sm:text-lg text-xl text-white focus:ring-4 focus:outline-none focus:ring-orangeprimary`,
  orangebutton: `font-nunito bg-orangeprimary hover:bg-pinkprimary rounded-full sm:py-3 sm:px-9 py-4 px-7 font-bold sm:text-lg text-xl text-white focus:ring-4 focus:outline-none focus:ring-orangeprimary drop-shadow-sm border-b-[5px] border-pinkprimary hover:border-orangeprimary`,
  pinkbutton: `font-nunito bg-pinkprimary hover:bg-orangeprimary rounded-full sm:py-3 sm:px-9 py-4 px-7 font-bold sm:text-lg text-xl text-white focus:ring-4 focus:outline-none focus:ring-pinkprimary`,
  lightbluebutton: `font-nunito bg-blueprimary hover:bg-orangeprimary rounded-full sm:py-3 sm:px-9 py-4 px-7 font-bold sm:text-lg text-xl text-darkprimary focus:ring-4 focus:outline-none focus:ring-pinkprimary`,
};

export default styles;
