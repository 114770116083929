import React from "react";
import styles from "../styles";

const PrivacyPolicy = () => {
  return (
    <div className={`container mx-auto p-4 ${styles.paragraph}`}>
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>

      <section className="mb-6">
        <h2 className={`${styles.headingdark} text-xl font-semibold mb-2`}>
          Introduction
        </h2>
        <p className={`mb-6`}>
          The Tech Tails website{" "}
          <a
            href="http://www.techtails.org"
            className="text-pinkprimary hover:text-orangeprimary"
          >
            www.techtails.org
          </a>{" "}
          and the classes and other services available there (collectively, the
          “Service”) are operated by Tech Tails, LLC, located at 1878 W 12600
          South PMB#314, Riverton, UT 84065 (“Tech Tails”). We take the private
          nature of your Personal Information very seriously and this Privacy
          Policy describes how we treat the information we collect from people
          who visit or use the Service, including the children who are enrolled
          in our various programs (“Enrollee(s)”). Please read this notice
          carefully.
        </p>
        <br />
        BY VISITING THE SERVICE, YOU EXPRESSLY CONSENT TO THE PROCESSING OF YOUR
        AND THE ENROLLEE’S PERSONAL INFORMATION ACCORDING TO THIS PRIVACY
        POLICY. IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOUR CHOICE
        IS TO NOT USE THE SERVICE. YOUR PERSONAL INFORMATION MAY BE PROCESSED IN
        THE COUNTRY WHERE IT WAS COLLECTED AS WELL AS OTHER COUNTRIES (INCLUDING
        THE UNITED STATES) WHERE LAWS REGARDING PROCESSING OF PERSONAL
        INFORMATION MAY BE LESS STRINGENT THAN THE LAWS IN YOUR COUNTRY. BY
        UPLOADING PERSONAL INFORMATION TO THE SERVICE, YOU WARRANT THAT YOU HAVE
        THE RIGHT TO TRANSFER SUCH INFORMATION OUTSIDE YOUR COUNTRY AND INTO THE
        UNITED STATES.
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Children’s Privacy</h2>
        The Service is for Enrollees under 18 years of age who use the Service
        under the express guidance and supervision of a parent or guardian who
        has accepted this Privacy Policy on the child’s behalf. By creating an
        account for your child, you are consenting to the collection, use, and
        disclosure of your child’s Personal Information as provided herein.
        Enrollees are not permitted to use the Services without your direct
        consent and guidance. We require that you assist your Enrollee in using
        the Services so that you may monitor the Personal Information that they
        are disclosing.
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          Personal Information We Collect
        </h2>
        In order to provide the Services, we will collect some information that
        identifies you and your associated Enrollees (“Personal Information”).
        This can include name, username, email address, city, state, demographic
        information (such as birthdate, age, sex, etc.), and any other
        information you provide us. We also collect records and copies of your
        correspondence (including email addresses), if you contact us. If you’re
        visiting the Service on your mobile device, you may also choose to
        provide us with location data. We keep all Personal Information strictly
        confidential and only use it in order to provide the Services, at your
        direction, or as otherwise set out in this Privacy Policy.
        <br />
        If you believe we might have any information collected from a child
        under 13 years of age in violation of this Privacy Policy, please
        contact us at teacher@techtails.org.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">
          Website Access Information and Use of Cookies
        </h2>
        Like many Services, the Service may use “cookies” to collect
        information. Tech Tails may use cookies to help identify and track
        visitors, their usage of the Service, and their website access
        preferences. Visitors who do not wish to have cookies placed on their
        computers should set their browsers to refuse cookies before using the
        Service.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Financial Data</h2>
        Tech Tails uses secure payment providers to process your payments if you
        purchase items or classes on the Service. When purchasing through our
        Services, Tech Tails stores your payment information subject to the
        privacy policy and terms of use of these secure payment providers. Tech
        Tails does not have access to your credit card information and does not
        store this information on our servers.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">
          Use and Disclosure of Personal Information
        </h2>
        Tech Tails will use the Personal Information that you or the Enrollee
        submit to us to provide you full access and functionality of the
        Services. We will never sell your Personal Information. Personal
        Information may be used for the following purposes: to provide and
        improve our services, features, and content; to administer your use of
        our services and accounts; to enable users to enjoy and easily navigate
        the Services; to better understand your needs and interests; to fulfill
        requests you may make; to personalize your experience; to provide you
        with announcements, notifications related to your use of the Services
        and other communications such as electronic newsletters, promotional
        e-mails or similar messaging; to provide service announcements; to
        protect against users seeking to hack into the Services; to assess the
        level of general interest in the Services and specific information
        included in the Services; for any other purpose with your consent.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">International Users</h2>
        For users outside the United States, please note that any
        personally-identifiable information you or the Enrollee enters into the
        Services will be transferred out of your country and into the United
        States. You consent to such transfer through your child’s use of the
        Services. You also warrant that you have the right to transfer such
        information outside your country and into the United States.
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
        <p>
          If you have any questions about this policy or our site in general,
          please contact us at{" "}
          <a
            href="mailto:teacher@techtails.org"
            className="text-pinkprimary hover:text-orangeprimary"
          >
            teacher@techtails.org
          </a>{" "}
          or (801) 704-7878.
        </p>
        <p>1878 W 12600 South PMB#314, Riverton, UT 84065</p>
      </section>
    </div>
  );
};
export default PrivacyPolicy;
