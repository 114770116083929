import React from "react";
import styles from "../../pages/styles";
import { kidpointing } from "../../assets/images";

const TechRequiredClasses = () => {
  return (
    <div className="w-full bg-pinkprimary pt-10 lg:px-32 px-4">
      <div className="flex sm:flex-row flex-col sm:gap-10 md:items-center sm:items-end items-center">
      <div className="items-end sm:order-first order-last">
          <img src={kidpointing} alt="Kid Pointing" className="max-w-[340px]"/>
        </div>
        <div className="md:p-10 md:pb-5 p-6 bg-whiteprimary h-full sm:mb-14 rounded-3xl items-center">
          <h2 className={`${styles.headingdark} mb-4`}>
            What's Needed for Virtual Class Setup
          </h2>
          <p className={`${styles.paragraph}`}>
            <ul className="list-disc pl-5">
              <li>
                Internet Speed: Aim for at least 10 Mbps upload and download.
              </li>
              <li>
                Mouse: A 2 or 3-button mouse with a scroll wheel is handy
                (suggested, not mandatory).
              </li>
              <li>
                Audio Gear: Microphone and speakers, or a headset with a mic.
                Optionally, a video camera.
              </li>
              <li>
                Storage Space: At least 3 GB of free space on your computer; 10
                GB for advanced classes.
              </li>
              <li>
                Device: Chromebook, PC, or Mac with a minimum of 8 GB RAM.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TechRequiredClasses;
