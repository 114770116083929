import React from "react";
import styles from "../../pages/styles";

const SkillCard = ({ title }) => {
  return (
    <h1
      className={`${styles.paragraphwhite} border-2 text-center rounded-2xl py-4 px-6`}
    >
      {title}
    </h1>
  );
};

const SkillsClasses = ({ title, desc }) => {
  return (
    <div className="w-full bg-pinkprimary py-14 md:px-48 px-2">
      <h1 className={`${styles.biggerheadingwhite} text-center mb-4`}>
        {title}
      </h1>
      <p className={`${styles.paragraphwhite} text-center mb-10`}>{desc}</p>
      <div className="grid grid-cols-2 justify-center sm:gap-4 gap-2">
        <SkillCard title="🧠 Strategic Thinking" />
        <SkillCard title="💻 Interactive Learning" />
        <SkillCard title="👫 Collaborative Environment" />
        <SkillCard title="👤 Personalized Attention" />
        <SkillCard title="🧩 Problem-Solving Skills" />
        <SkillCard title="💭 Innovation Encouraged" />
        <SkillCard title="📚 Project-Based Learning" />
        <SkillCard title="🖥️ Digital Literacy" />
      </div>
    </div>
  );
};

export default SkillsClasses;
