import { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../Shared/firebase";

const useContactForm = () => {
  const [formData, setFormData] = useState({
    parentname: "",
    phone: "",
    studentname: "",
    email: "",
    contactpreference: "",
    referralSource: "", // Add this line for referral source
    message: "",
    referralOther: "",
    submitted: false,
  });

  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    const numbers = value.replace(/[^\d]/g, "");
    // Clamp length of numbers to a maximum of 10
    const clampedNumbers = numbers.substring(0, 10);
    // Format the numbers as (XXX) XXX-XXXX
    const match = clampedNumbers.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
        match[3] ? "-" : ""
      }${match[3]}`;
    }
    return value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "phone") {
      formattedValue = formatPhoneNumber(value);
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      timestamp: serverTimestamp(),
      contacted: false,
    };
    try {
      await addDoc(collection(db, "submissions"), submissionData);
      setFormData({
        parentname: "",
        phone: "",
        studentname: "",
        email: "",
        contactpreference: "",
        referralSource: "",
        message: "",
        referralOther: "",
        submitted: true,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(error.message);
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    submitted: formData.submitted,
  };
};

export default useContactForm;
