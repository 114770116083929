import React from "react";
import styles, { elements } from "../../pages/styles";
import {
  teacherzoom,
  textlogo,
  laptopzoom,
  zoomflipped,
  zoomcall,
} from "../../assets/images";

const ZoomHowTo = ({ title, desc, img, bgcolor }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center rounded-t-xl relative p-8 overflow-hidden -mt-4`}
    >
      <div className="absolute bg-blueprimary h-[600px] w-[600px] rounded-full -top-64 -left-64 opacity-30 z-0"></div>
      <div className="absolute bg-blueprimary h-[500px] w-[500px] rounded-full -bottom-64 -right-64 opacity-30 z-0"></div>
      <div className="absolute bg-pinkprimary h-[400px] w-[400px] rounded-full -top-32 -right-48 opacity-30 z-0"></div>
      <div className="absolute bg-pinkprimary h-[300px] w-[300px] rounded-full -bottom-32 -left-32 opacity-30 z-0"></div>

      <div className="flex flex-col md:w-11/12 w-full order-first -mt-4 relative z-10 text-center items-center">
        <img src={textlogo} alt="Zoom Guide" className="w-40 mt-4" />
        <h1 className={`${styles.biggerheadingdark} my-8`}>
          How to Install Zoom and Enable Screen Sharing
        </h1>
        <p className={`${styles.paragraph} mb-8`}>
          Follow these steps to install Zoom and ensure screen sharing is
          enabled. We use Zoom to provide an interactive and engaging learning
          experience. You will receive a link to join the class in our reminder
          emails.
        </p>
      </div>

      <div
        className={`${styles.paragraph} flex flex-col items-center w-full md:w-11/12 relative z-10 mb-8 space-y-8`}
      >
        <div className="flex flex-col sm:flex-row w-full items-center md:space-x-4">
          <div className="w-full md:w-1/2">
            <h2 className="font-bold text-left">For Windows:</h2>
            <ol className="list-decimal ml-4 text-left">
              <li>
                Download Zoom from{" "}
                <a href="https://zoom.us/download" className="text-blueprimary">
                  zoom.us/download
                </a>
                .
              </li>
              <li>Run the installer and follow the instructions.</li>
              <li>Open Zoom and sign in.</li>
              <li>
                Click on your profile picture and select{" "}
                <strong>Settings</strong>.
              </li>
              <li>
                Go to the <strong>Share Screen</strong> tab.
              </li>
              <li>
                Ensure the <strong>"Enable screen sharing"</strong> option is
                checked.
              </li>
              <li>
                If prompted by Windows, allow the necessary permissions for Zoom
                to share your screen.
              </li>
            </ol>
          </div>
          <div className="w-full  mt-4 md:mt-0 flex justify-end">
            <div className="relative">
              <img src={laptopzoom} alt="Windows Setup" />
              <img
                src={zoomflipped}
                alt="Teacher Zoom"
                className="lg:w-80 md:w-64 w-64 absolute !left-7 !top-8 teacherZoomImg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full items-center sm:space-x-4">
          <div className="w-full md:w-1/2 sm:order-2">
            <h2 className="font-bold sm:ml-20 ml-2 text-left">For Mac:</h2>
            <ol className="list-decimal sm:ml-20 ml-4 text-left">
              <li>
                Download Zoom from{" "}
                <a href="https://zoom.us/download" className="text-blueprimary">
                  zoom.us/download
                </a>
                .
              </li>
              <li>
                Open the downloaded file and follow the installation
                instructions.
              </li>
              <li>Open Zoom and sign in.</li>
              <li>
                Click on your profile picture and select{" "}
                <strong>Settings</strong>.
              </li>
              <li>
                Go to the <strong>Share Screen</strong> tab.
              </li>
              <li>
                Ensure the <strong>"Enable screen sharing"</strong> option is
                checked.
              </li>
              <li>
                If prompted, go to <strong>System Preferences</strong> >{" "}
                <strong>Security & Privacy</strong> > <strong>Privacy</strong>{" "}
                tab > <strong>Screen Recording</strong> and check the box for
                Zoom.
              </li>
              <li>
                You might need to restart Zoom for the changes to take effect.
              </li>
            </ol>
          </div>
          <div className="w-1/2 md:w-1/2 mt-4 md:mt-0">
            <img
              src={zoomcall}
              alt="Mac Setup"
              className="w-full rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center relative z-10 mt-8">
        <p className={`${styles.paragraph} mb-8 text-center !font-bold`}>
          We will send you a link to join the Zoom meeting in our class reminder
          email.
        </p>
        <p className={`${styles.paragraph} mb-4 text-center`}>
          If you have any questions, feel free to contact us!
        </p>
        <a className={`${elements.bluebutton} !py-3 `} href="/pages/contactus">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default ZoomHowTo;
