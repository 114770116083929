import React, { useState } from "react";
import styles from "../../pages/styles";

const PricingClasses = ({ title, desc }) => {
  const pricingData = [
    {
      title: "Private Class 1x a Week 30min",
      description:
        "Short bursts of creativity and learning! Weekly 30 minute-long sessions.",
      price: "$135/month",
    },
    {
      title: "Private Class 1x a Week 1hr",
      description:
        "Incredible progress awaits in our one-on-one, weekly hour-long sessions.",
      price: "$240/month",
    },
    {
      title: "Private Class 2x a Week 1hr",
      description:
        "Twice the sessions, means double the learning. Weekly two hour-long sessions.",
      price: "$470/month",
    },
  ];

  return (
    <div className="flex flex-col items-center py-10 md:px-40 px-4 gap-2 bg-blueprimary">
      <h2 className={`${styles.biggerheadingdark}`}>{title}</h2>
      <p className={`${styles.paragraph} text-center mb-5`}>{desc}</p>
      <div className="flex flex-col md:flex-row justify-evenly md:gap-10 gap-5">
        {pricingData.map((pricing, index) => (
          <div className="bg-white rounded-lg shadow-md p-4 md:p-8 w-full md:w-1/3 mx-auto">
            <p className={`${styles.paragraph} text-center !font-bold mb-4`}>
              {pricing.title}
            </p>
            <h2 className={`${styles.headingdark} text-center mb-4`}>
              {pricing.price}
            </h2>
            <p className={`${styles.paragraph} text-center`}>
              {pricing.description}
            </p>
          </div>
        ))}
      </div>
      <a
        href="/pages/freetrial"
        className={`${styles.paragraphwhite} bg-pinkprimary text-white px-8 py-4 rounded-3xl mt-4 block mx-auto focus:border-pinkprimary focus:border-4 hover:bg-orangeprimary`}
      >
        Schedule Trial Class
      </a>
    </div>
  );
};

export default PricingClasses;
